import { LoadingButton } from "@mui/lab";
import { useCallback, useState } from "react";
import { DomainHealthRecord } from "../../../../domain/healthRecord";
import { useI18n } from "../../../hooks/useI18n";

interface ValidateProfileProps {
  validateProfile: () => Promise<void>;
  refreshCallback: () => void;
  healthRecord: DomainHealthRecord;
}

export const ProfileValidation = (props: ValidateProfileProps) => {
  const { t } = useI18n();
  const { validateProfile, refreshCallback, healthRecord } = props;
  const [isSending, setIsSending] = useState(false);

  const validateHandler = useCallback(async () => {
    setIsSending(true);
    try {
      await validateProfile();
      refreshCallback();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSending(false);
    }
  }, [validateProfile, refreshCallback]);

  return (
    <div>
      {healthRecord.validatorIds.length === 0 && (
        <LoadingButton
          color="primary"
          variant="contained"
          sx={{ marginBottom: "9px" }}
          onClick={validateHandler}
          disabled={isSending}
          disableElevation
          loading={isSending}
        >
          {t("profile.validate")}
        </LoadingButton>
      )}
    </div>
  );
};
