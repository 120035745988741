import { ApiClient as ArianeApi, initAriane } from "@syadem/ariane-js";
import {
  Configuration as ProConfiguration,
  ProfessionalAccountApi,
  HealthRecordApi as ProHealthRecordApi,
  RecordSharingApi as ProRecordSharingApi,
  SharingRequestApi as ProSharingRequestApi,
  VaccinationActApi as ProVaccinationActApi,
  UnsharedHealthRecordApi as ProUnsharedHealthRecordApi,
  NoteApi as ProNoteApi,
  HealthProfileApi as ProHealthProfileApi,
} from "@syadem/kairos-pro-js";
import {
  OrganizationApi,
  RecordSharingApi as TeamRecordSharingApi,
  TeamApi,
  Configuration as TeamConfiguration,
  HealthRecordApi as TeamHealthRecordApi,
  SharingRequestApi as TeamSharingRequestApi,
  VaccinationActApi as TeamVaccinationActApi,
  UnsharedHealthRecordApi as TeamUnsharedHealthRecordApi,
  NoteApi as TeamNoteApi,
  HealthProfileApi as TeamHealthProfileApi,
  EventLogApi as TeamEventLogApi,
} from "@syadem/kairos-team-js";
import { SadvApi, Configuration as SadvApiConfiguration } from "@syadem/sad-js";
import { ProKairosCertApi } from "./kairosCertApi";
import { TeamKairosCertApi } from "./kairosCertApi";

interface ApisDependencies {
  kairosProApiBasePath: string;
  kairosTeamApiBasePath: string;
  kairosCertApiBasePath: string;
  sadApiBasePath: string;
  arianeApiBasePath: string;
  accessToken: () => Promise<string>;
}

export interface Apis {
  pro: {
    healthRecordApi: ProHealthRecordApi;
    professionalAccountApi: ProfessionalAccountApi;
    recordSharingApi: ProRecordSharingApi;
    sharingRequestApi: ProSharingRequestApi;
    vaccinationActApi: ProVaccinationActApi;
    unsharedHealthRecordApi: ProUnsharedHealthRecordApi;
    noteApi: ProNoteApi;
    healthProfileApi: ProHealthProfileApi;
    kairosCertApi: ProKairosCertApi;
  };
  team: {
    healthRecordApi: TeamHealthRecordApi;
    teamApi: TeamApi;
    organizationApi: OrganizationApi;
    recordSharingApi: TeamRecordSharingApi;
    sharingRequestApi: TeamSharingRequestApi;
    vaccinationActApi: TeamVaccinationActApi;
    unsharedHealthRecordApi: TeamUnsharedHealthRecordApi;
    noteApi: TeamNoteApi;
    healthProfileApi: TeamHealthProfileApi;
    kairosCertApi: TeamKairosCertApi;
    eventLogApi: TeamEventLogApi;
  };
  sadApi: SadvApi;
  arianeApi: ArianeApi;
}

export function initApis(dependencies: ApisDependencies): Apis {
  const kairosProApiConfig = new ProConfiguration({
    basePath: dependencies.kairosProApiBasePath,
    accessToken: dependencies.accessToken,
  });

  const kairosTeamApiConfig = new TeamConfiguration({
    basePath: dependencies.kairosTeamApiBasePath,
    accessToken: dependencies.accessToken,
  });

  return {
    pro: {
      healthRecordApi: new ProHealthRecordApi(kairosProApiConfig),
      professionalAccountApi: new ProfessionalAccountApi(kairosProApiConfig),
      recordSharingApi: new ProRecordSharingApi(kairosProApiConfig),
      sharingRequestApi: new ProSharingRequestApi(kairosProApiConfig),
      vaccinationActApi: new ProVaccinationActApi(kairosProApiConfig),
      unsharedHealthRecordApi: new ProUnsharedHealthRecordApi(kairosProApiConfig),
      noteApi: new ProNoteApi(kairosProApiConfig),
      healthProfileApi: new ProHealthProfileApi(kairosProApiConfig),
      kairosCertApi: new ProKairosCertApi(kairosProApiConfig, dependencies.kairosCertApiBasePath),
    },
    team: {
      healthRecordApi: new TeamHealthRecordApi(kairosTeamApiConfig),
      teamApi: new TeamApi(kairosTeamApiConfig),
      organizationApi: new OrganizationApi(kairosTeamApiConfig),
      recordSharingApi: new TeamRecordSharingApi(kairosTeamApiConfig),
      sharingRequestApi: new TeamSharingRequestApi(kairosTeamApiConfig),
      vaccinationActApi: new TeamVaccinationActApi(kairosTeamApiConfig),
      unsharedHealthRecordApi: new TeamUnsharedHealthRecordApi(kairosTeamApiConfig),
      noteApi: new TeamNoteApi(kairosTeamApiConfig),
      healthProfileApi: new TeamHealthProfileApi(kairosTeamApiConfig),
      kairosCertApi: new TeamKairosCertApi(kairosTeamApiConfig, dependencies.kairosCertApiBasePath),
      eventLogApi: new TeamEventLogApi(kairosTeamApiConfig),
    },
    sadApi: new SadvApi(new SadvApiConfiguration({ basePath: dependencies.sadApiBasePath })),
    arianeApi: initAriane(dependencies.arianeApiBasePath),
  };
}
