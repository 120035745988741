import { ProfessionalAccount } from "@syadem/kairos-pro-js";
import { Organization as SubscriptionOrganization } from "@syadem/kairos-subscription-js";
import { TeamMember } from "@syadem/kairos-team-js";
import { useContext } from "react";
import { devtools } from "zustand/middleware";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { createStore } from "zustand/vanilla";
import { ActionResult } from "./actionResult";
import { Organization } from "./domain/organization";
import { Team } from "./domain/team";
import { User } from "./domain/user";
import { DependenciesContext } from "./ui/providers/Dependencies";
import { Log } from "./domain/log";
import { DomainHealthRecord } from "./domain/healthRecord";

type AuthState = { type: "signed-out" } | { type: "signed-in"; user: User } | { type: "error"; error: Error };

export interface AppState {
  authState: AuthState;
  actionResults: ActionResult<unknown>[];
  organizations?: { [id: string]: Organization };
  teamMembers?: TeamMember[];
  currentAccount?: ProfessionalAccount;
  teams?: { [id: string]: Team };
  subscriptionOrganizations?: { [id: string]: SubscriptionOrganization };
  healthRecords?: { [id: string]: DomainHealthRecord };
  proHealthRecordIds?: string[];
  teamsHealthRecordIds: { [teamId: string]: { ids: string[]; count: number } };
  eventLogsById?: { [id: string]: Log };
  lastEventLogsIds: string[] | null;
  lastEventLogsIdsStringify: string | null;
  membersById?: { [id: string]: ProfessionalAccount };
}

export type AppStore = ReturnType<typeof initStore>;

export function initStore() {
  const devtoolsImpl = import.meta.env.MODE === "development" ? devtools : (((x) => x) as typeof devtools);

  return createStore<AppState>()(
    devtoolsImpl(
      () =>
        ({
          authState: { type: "signed-out" },
          actionResults: [],
          eventLogsById: {},
          teamsHealthRecordIds: {},
          lastEventLogsIds: null,
          lastEventLogsIdsStringify: null,
          membersById: {},
        }) as AppState,
    ),
  );
}

export function useAllOrganizations(): { [id: string]: Organization } | undefined {
  return useContextStore(allOrganizationsSelector);
}

export function useOrganization(id: string | undefined): Organization | null {
  return useContextStore((state) => {
    if (!id) {
      return null;
    }

    return (allOrganizationsSelector(state) || {})[id] ?? null;
  });
}

export function useSubscriptionOrganizations(): { [id: string]: SubscriptionOrganization } | undefined {
  return useContextStore(subscriptionOrganizationsSelector);
}

export function useSubscriptionOrganization(id: string | undefined): SubscriptionOrganization | null {
  return useContextStore((state) => {
    if (!id) {
      return null;
    }

    return (subscriptionOrganizationsSelector(state) || {})[id] ?? null;
  });
}

export function useTeamsForOrganizationId(organizationId: string | undefined): Team[] {
  return useContextStore((state) => {
    if (!organizationId) {
      return [];
    }

    const teams = teamsSelector(state) || {};

    return Object.entries(teams)
      .filter(([_, t]) => t.organizationId == organizationId)
      .map(([_, t]) => t);
  });
}

export function useTeamMembers(): TeamMember[] | undefined {
  return useContextStore(teamMembersSelector);
}

export function useCurrentAccount(): ProfessionalAccount | undefined {
  return useContextStore(professionalAccountSelector);
}

export function useTeams(): { [id: string]: Team } | undefined {
  return useContextStore(teamsSelector);
}

export function useTeamById(id: string): Team | undefined {
  const teams = useContextStore(teamsSelector);
  return teams ? teams[id] : undefined;
}

export function useAuthState(): AppState["authState"] {
  return useContextStore(authStateSelector);
}

export function useProHealthRecords(): DomainHealthRecord[] | undefined {
  return useContextStore(proHealthRecordsSelector);
}

export function useTeamHealthRecords(teamId: string | null): {
  healthRecords: DomainHealthRecord[] | undefined;
  count: number;
} {
  return useContextStore((state) => teamHealthRecordsSelector(state, teamId));
}

export function useHealthRecord(id: string): DomainHealthRecord | undefined {
  return useContextStore((state) => healthRecordSelector(state, id));
}

export function useAuthenticatedUser(): User {
  const user = useContextStore(userSelector);
  if (!user) throw new Error("Unexpected null user");
  return user;
}

export function useLastEventLogsIds(): string[] | null {
  return useContextStore(lastEventLogsIdsSelector);
}

const lastEventLogsIdsSelector = (state: AppState): string[] | null => state.lastEventLogsIds;

export function useEventLog(id: string): Log | null {
  return useContextStore((state) => eventLogByIdsSelector(state, id));
}

const eventLogByIdsSelector = (state: AppState, id: string): Log | null => {
  return state.eventLogsById?.[id] ?? null;
};

export function useMemberById(id: string): ProfessionalAccount | null {
  return useContextStore((state) => memberByIdSelector(state, id));
}

const allOrganizationsSelector = (state: AppState) => state.organizations;
const subscriptionOrganizationsSelector = (state: AppState) => state.subscriptionOrganizations;
const teamMembersSelector = (state: AppState) => state.teamMembers;
const professionalAccountSelector = (state: AppState) => state.currentAccount;
const teamsSelector = (state: AppState) => state.teams;
const authStateSelector = (state: AppState) => state.authState;
const proHealthRecordsSelector = (state: AppState) => {
  const healthRecords = state.healthRecords;
  if (healthRecords === undefined || state.proHealthRecordIds === undefined) return undefined;
  return state.proHealthRecordIds.map((id) => healthRecords[id]).filter((healthRecord) => healthRecord !== undefined);
};
const teamHealthRecordsSelector = (state: AppState, teamId: string | null) => {
  if (teamId === null) return { healthRecords: undefined, count: 0 };

  const healthRecords = state.healthRecords;
  if (healthRecords === undefined) return { healthRecords: undefined, count: 0 };

  const teamHealthRecordsIds = state.teamsHealthRecordIds[teamId];
  if (teamHealthRecordsIds === undefined) return { healthRecords: undefined, count: 0 };

  const teamHealthRecords = teamHealthRecordsIds.ids
    ?.map((id) => healthRecords[id])
    ?.filter((healthRecord) => healthRecord !== undefined);

  return {
    healthRecords: teamHealthRecords,
    count: state.teamsHealthRecordIds[teamId].count,
  };
};
const healthRecordSelector = (state: AppState, id: string) =>
  state.healthRecords ? state.healthRecords[id] : undefined;
const userSelector = (state: AppState) => (state.authState.type === "signed-in" ? state.authState.user : null);
const memberByIdSelector = (state: AppState, id: string): ProfessionalAccount | null => {
  return state.membersById?.[id] ?? null;
};

function useContextStore<T>(selector: (state: AppState) => T): T {
  const deps = useContext(DependenciesContext);
  if (!deps) {
    throw new Error("No dependencies container, did you forget to pass it to the provider?");
  } else {
    return useStoreWithEqualityFn(deps.store, selector);
  }
}
