import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Avatar, Box, Button, ListItem, ListItemAvatar, ListItemText, Tooltip, useMediaQuery } from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { Daphne } from "@syadem/daphne-js";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { Link } from "react-router-dom";
import { DomainHealthRecord } from "../../domain/healthRecord";
import { useAuthenticatedUser } from "../../store";
import { calculateAge } from "../../utils/ageCalculator";
import { certificationDescription } from "../../utils/certificationDescription";
import { localizedDate } from "../../utils/dayjs";
import { isEditableVaccination } from "../../utils/isEditableVaccination";
import { useAppContext } from "../hooks/useAppContext";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";
import { ListItemLink } from "./shared/ListItemLink";
import { VaccinationActCertifyButton } from "./VaccinationActCertifyButton";
import { isCertified, Vaccine } from "./Vaccine";
import { DomainVaccinationAct, getCertificate } from "../../domain/VaccinationAct";

interface VaccinationActListItemProps {
  vaccinationAct: ProVaccinationAct | TeamVaccinationAct;
  healthRecord: DomainHealthRecord;
  showActions: boolean;
  refreshCallback?: () => void;
  daphne: Daphne;
  certificators?: (Professional | Team)[];
  displayDiseases?: boolean;
}

export function VaccinationActListItem(props: VaccinationActListItemProps) {
  const {
    vaccinationAct,
    healthRecord,
    showActions,
    refreshCallback,
    daphne,
    certificators,
    displayDiseases = true,
  } = props;
  const { t, locale } = useI18n();
  const { teamId } = useAppContext();
  const currentUser = useAuthenticatedUser();
  const breakpointMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const certificate = getCertificate(vaccinationAct as DomainVaccinationAct);

  return (
    <ListItem
      secondaryAction={
        showActions &&
        refreshCallback && (
          <Box display="flex" sx={{ mr: 1, mt: -1 }}>
            {!isCertified(vaccinationAct.certificationMethod) && (
              <VaccinationActCertifyButton
                vaccinationAct={vaccinationAct}
                birthDate={healthRecord.birthDate}
                refreshCallback={refreshCallback}
              />
            )}
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={`vaccination/${vaccinationAct.id}`}
              startIcon={
                isEditableVaccination(vaccinationAct, currentUser?.id, teamId ?? undefined) ? (
                  <EditOutlinedIcon color="primary" />
                ) : (
                  <VisibilityIcon color="primary" />
                )
              }
              disableElevation
              sx={{ ml: 1, width: { xs: "auto", md: "110px" } }}
              size={breakpointMdUp ? "medium" : "small"}
            >
              {isEditableVaccination(vaccinationAct, currentUser?.id, teamId ?? undefined)
                ? t("common.cta.edit")
                : t("common.cta.view")}
            </Button>
          </Box>
        )
      }
      sx={{
        mb: 0.8,
        p: 0,
        ".MuiListItemSecondaryAction-root": {
          right: { xs: 0, md: 16 },
          bottom: { xs: 8, md: 16 },
          top: { xs: "unset", md: "50%" },
          transform: { xs: "unset", md: "translateY(-50%)" },
        },
      }}
      className={isCertified(vaccinationAct.certificationMethod) ? "vaccinationAct" : "vaccinationActNotCertified"}
    >
      <ListItemLink
        to={`vaccination/${vaccinationAct.id}`}
        sx={{
          pb: { xs: showActions ? 6 : 1, md: 1 },
          border: `solid 1px ${theme.palette.neutral[200]}`,
          borderRadius: 1,
          bgcolor: "background.paper",
          "&:hover": {
            border: `solid 1px ${theme.palette.primary[500]}`,
            bgcolor: "background.paper",
          },
        }}
        data-testid="health-record"
      >
        <ListItemAvatar>
          <Tooltip
            title={
              certificate && certificators ?
                certificationDescription(certificators, certificate, teamId ?? undefined, t, locale)
                : t("vaccines.not_validated")
            }
            arrow
          >
            <Avatar
              sx={{
                background:
                  isCertified(vaccinationAct.certificationMethod) && certificators && certificators.length > 0
                    ? theme.palette.success[100]
                    : theme.palette.neutral[100],
                width: { xs: 40, sm: 48 },
                height: { xs: 40, sm: 48 },
                mr: 2,
              }}
            >
              <TaskAltOutlinedIcon
                sx={{
                  color:
                    isCertified(vaccinationAct.certificationMethod) && certificators && certificators.length > 0
                      ? theme.palette.success[500]
                      : theme.palette.neutral[500],
                }}
              />
            </Avatar>
          </Tooltip>
        </ListItemAvatar>
        <Box>
          <ListItemText
            primary={
              <Vaccine
                vaccineId={vaccinationAct.vaccineId}
                daphne={daphne}
                displayDiseases={displayDiseases}
                showActions={showActions}
              />
            }
            secondary={t(`vaccines.${vaccinationAct.booster ? "administration_booster" : "administration"}`, {
              date: localizedDate(vaccinationAct.performedOn, locale),
              age: calculateAge(vaccinationAct?.performedOn, healthRecord?.birthDate, true, t),
            })}
          />
        </Box>
      </ListItemLink>
    </ListItem>
  );
}
